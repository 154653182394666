<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <ValidationObserver ref="form">
          <CCardHeader tag="div">
            <CIcon name="cil-grid"/>
            Chi tiết Đề xuất Xúc tiến đầu tư của Bộ, Ngành
          </CCardHeader>
          <CCardBody>
            <CForm>
              <div class="w-100">
                <div class="pdf-wrapper">
                  <div class="">
                    <div class="pdf-page">
                      <div class="fs-16">
                        <div id="pdf1" class="pdf-content">
                          <div class="text-center fs-16">
                            <strong class="bold">Mẫu C.II.1</strong><br>
                            <strong>Văn bản dự kiến chương trình xúc tiến đầu tư hàng năm</strong><br>
                            <p class="font-italic">(Áp dụng đối với chương trình xúc tiến đầu tư của Bộ/Ủy ban nhân dân
                              cấp
                              tỉnh theo quy định tại Điểm a, Khoản 2, Điều 93 Nghị định số 31/2021/NĐ-CP ngày
                              26/3/2021)</p>
                            <hr>
                          </div>
                          <div class="row fs-16">
                            <div class="col-md-6">
                              <ValidationProvider name="Tên Bộ/UBND Tỉnh" rules="required"
                                                  v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                                    <input disabled id="tenBo_UBNDTinh" type="text" class="form-control" style="background-color: white"
                                           placeholder="Tên Bộ/UBND Tỉnh"
                                           :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                           v-model="detailItem.tenBo_UBNDTinh"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                              <!--                        <CInput placeholder="Tên Bộ/UBND Tỉnh" horizontal :value.sync="detailItem.tenCoQuanChuTri"/>-->
                              <ValidationProvider name="Số văn bản" rules="required"
                                                  v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                                    <input disabled id="soVanBan" type="text" class="form-control" style="background-color: white"
                                           placeholder="Số:"
                                           :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                           v-model="detailItem.soVanBan"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                              <!--                        <CInput placeholder="Số:" horizontal :value.sync="detailItem.soVanBan"/>-->
                              <p class="font-weight">V/v dự kiến chương trình đầu tư năm
                                {{ detailItem.nam_ChuongTrinh }}</p>
                            </div>
                            <div class="col-md-6">
                              <p class="text-center text-uppercase font-weight-bold">Cộng hòa xã hội chủ nghĩa Việt
                                Nam</p>
                              <p class="text-center font-weight-bold">Độc lập – Tự do – Hạnh phúc</p>
                              <p class="font-weight font-italic" style="float: right; display: flex">
                                <ValidationProvider name="Địa điểm" rules="required"
                                                    v-slot="{ errors, valid , touched }">
                              <div role="group" class="form-group form-row">
                                <div class="col-sm-9">
                                  <input disabled id="diaDiem" type="text" class="form-control" style="background-color: white"
                                         placeholder="Địa điểm"
                                         :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                         v-model="detailItem.diaDiem"/>
                                  <div class="invalid-feedback" v-if="!valid">
                                    <ul>
                                      <li v-for="e in errors" :key="e">{{ e }}</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              </ValidationProvider>
                              <span class="mt-2">, ngày {{
                                  getDay(detailItem.ngayVanBan)
                                }} tháng {{
                                  getMonth(detailItem.ngayVanBan)
                                }} năm {{ getYear(detailItem.ngayVanBan) }}</span>
                              </p>
                            </div>
                          </div>
                          <div class="text-center">
                            <p>Kính gửi: Bộ Kế hoạch và Đầu tư</p>
                          </div>
                          <div>
                            <b>I. Dự kiến chương trình xúc tiến đầu tư năm
                              {{ detailItem.nam_ChuongTrinh }}</b>
                            <div class="col-12">
                              <p>1. Đánh giá sơ bộ hoạt động xúc tiến đầu tư của năm trước:</p>
                              <ValidationProvider name="Đánh giá hoạt động năm trước" rules="required"
                                                  v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                              <textarea disabled id="danhGiaHoatDongNamTruoc" type="text" class="form-control" :rows="1" style="background-color: white"
                                        placeholder="Nhập đánh giá"
                                        :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                        v-model="detailItem.danhGiaHoatDongNamTruoc"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                              <!--                        <CTextarea :rows="1" placeholder="Nhập đánh giá" horizontal :value.sync="item.danhGiaHoatDongNamTruoc"/>-->
                              <p>2. Quan điểm, định hướng, mục tiêu của chương trình xúc tiến đầu tư:</p>
                              <ValidationProvider name="Quan điểm, định hướng, mục tiêu" rules="required"
                                                  v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                              <textarea disabled id="quanDiemDinhHuongMucTieu" type="text" class="form-control" :rows="1" style="background-color: white"
                                        placeholder="Nhập định hướng"
                                        :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                        v-model="detailItem.quanDiemDinhHuongMucTieu"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                              <!--                        <CTextarea :rows="1" placeholder="Nhập quan điểm, định hướng, mục tiêu" horizontal-->
                              <!--                                   :value.sync="detailItem.diaDiem"/>-->
                              <p>3. Dự kiến chương trình xúc tiến đầu tư (trong đó nêu rõ lý do, sự phù hợp...):</p>
                              <ValidationProvider name="Dự kiến CT XTĐT" rules="required"
                                                  v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                              <textarea disabled id="duKienChuongTrinh" type="text" class="form-control" :rows="1" style="background-color: white"
                                        placeholder="Nhập dự kiến CT XTĐT"
                                        :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                        v-model="detailItem.duKienChuongTrinh"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                              <!--                        <CTextarea :rows="1" placeholder="Nhập chương trình dự kiến" horizontal-->
                              <!--                                   :value.sync="detailItem.diaDiem"/>-->
                            </div>
                          </div>
                          <div>
                            <b>II. Tổ chức thực hiện</b>
                            <div class="col-12">
                              <ValidationProvider name="Tổ chức thực hiện" rules="required"
                                                  v-slot="{ errors, valid , touched }">
                                <div role="group" class="form-group form-row">
                                  <div class="col-sm-9">
                              <textarea disabled id="toChucThucHien" type="text" class="form-control" :rows="1" style="background-color: white"
                                        placeholder="Nhập (các) tổ chức"
                                        :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"
                                        v-model="detailItem.toChucThucHien"/>
                                    <div class="invalid-feedback" v-if="!valid">
                                      <ul>
                                        <li v-for="e in errors" :key="e">{{ e }}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div>
                            <b>III. Kiến nghị, đề xuất</b>
                            <div class="col-12 row">
                              <div class="col">
                                <p class="font-italic">Phụ lục gửi kèm</p>
                                <p>(i) Biểu tổng hợp dự kiến chương trình xúc tiến đầu tư năm
                                  {{ detailItem.nam_ChuongTrinh }} <span class="font-italic">(Mẫu C.II.2)</span>;</p>
                                <p>(ii) Danh mục dự án đầu tư đang có nhà đầu tư quan tâm <span class="font-italic">(Mẫu A.IV.4)</span>.
                                </p>
                              </div>
                              <div class="col mt-5">
                                <CButton size="sm" class="mt-3 ml-3" color="info" @click="toggleDetailPhuLucModal({show:true})">
                                  <CIcon name="cil-options"/> Xem phụ lục
                                </CButton>
                              </div>
                            </div>
                            <div class="col-12 row">
                              <div class="col">
                                <div class="float-left">
                                  <span class="font-italic font-weight-bold">Nơi nhận:</span> <br>
                                  <span class="font-weight">- Như trên;</span> <br>
                                  <span class="font-weight">- Lưu: VT, ...</span> <br>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 row">
                              <div class="col"></div>
                              <div class="col text-center">
                                <span class="font-weight-bold text-uppercase">Thủ trưởng cơ quan</span> <br>
                                <span class="font-weight font-italic">(Ký, ghi rõ họ tên, chức danh và đóng dấu)</span>
                                <br>
                                <br>
                                <br>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row mb-0 mt-3">
                            <div class="col-12">
                              <b class="mt-5">Đính kèm file</b>
                              <div class="import-files mb-2" v-if="Attachments.length">
                                <div class="files mb-1" v-for="file in Attachments" v-bind:key="file.id">
                                  <a :href="getDownloadURL(file.url)" target="_blank">{{ file.fileName }}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-12" v-if="yKienGanNhat.dinhKem">
                            <label>Ý kiến phê duyệt gần nhất: <span class="font-weight-bold">{{ yKienGanNhat.noiDung}}</span></label>
                            <div class="import-files mb-2">
                              <div class="files mb-1" v-for="file in JSON.parse(yKienGanNhat.dinhKem)" v-bind:key="file.id">
                                <a :href="getDownloadURL(file.url)" target="_blank">{{ file.fileName }}</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CForm>
            <CElementCover v-if="isLoading"/>
          </CCardBody>
          <CCardFooter tag="div" class="text-right">
            <CButton color="secondary" class="mr-2" @click="back">Quay lại</CButton>
            <CButton color="ghost" class="mr-2" @click="print">Xuất văn bản</CButton>
<!--            <CButton color="danger" @click="isDeleteConfirmationModalOpen = true">Xóa</CButton>-->
          </CCardFooter>
          <DeXuatCTXTDTPhuLucDetailModal />
        </ValidationObserver>
      </CCard>
    </CCol>
    <DeleteConfirmationModal
      title="Xóa Đề xuất Chương trình Xúc tiến đầu tư này?"
      :show.sync="isDeleteConfirmationModalOpen"
      :is-deleting="isDeleting"
      :item="item" @confirm-destroy="confirmDestroy"/>
  </CRow>
</template>

<script>
import { statuses, loaiDonVis } from '@/shared/appConstants'
import { mapGetters, mapActions } from 'vuex'
import {
  DELETE_DEXUATCTXTDT,
  GET_DEXUATCTXTDT, GET_HISTORYDETAIL, TOGGLE_DETAIL_MODAL
} from '@/store/modules/DeXuatCTXTDT_BoNganh/actionTypes'
import { tinhThanhService } from '@/services/tinh-thanh.service'
import { nganhLinhVucService } from '@/services/nganh-linh-vuc.service'
import { quocGiaService } from '@/services/quoc-gia.service'
import moment from 'moment'
import DeXuatCTXTDTPhuLucDetailModal from '@/components/DeXuatCTXTDT_BoNganh/DeXuatCTXTDTPhuLucDetailModal'
import appConfig from '@/shared/appConfig'
import { GET_YKIENPHEDUYET, GET_YKIENPHEDUYETGANNHAT } from '@/store/modules/VanBanTBKHTCHDXTHHDTTMDLNGKT/actionTypes'

export default {
  name: 'DeXuatCTXTDTDetail',
  // props: ['itemid'],
  components: {
    DeXuatCTXTDTPhuLucDetailModal
  },
  data () {
    return {
      invalid: true,
      Attachments: [],
      type: 'DeXuatCTXTDT_BoNganh',
      isLoading: false,
      optionsTrangThai: statuses,
      optionsLoaiDonVi: loaiDonVis,
      isDeleteConfirmationModalOpen: false,
      optionsTinhThanh: [],
      optionsQuocGia: [],
      optionsNganhLinhVuc: [],
      item: this.detailItem,
      yKienGanNhat: {
        noiDung: '',
        dinhKem: ''
      }
    }
  },
  computed: {
    ...mapGetters('deXuatCTXTDT', {
      detailItem: 'detailItem',
      isFetchingSingle: 'isFetchingSingle',
      isDeleting: 'isDeleting',
      yKienPheDuyetGanNhat: 'yKienPheDuyetGanNhat',
      yKienPheDuyet: 'yKienPheDuyet',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('deXuatCTXTDT', {
      getItem: GET_DEXUATCTXTDT,
      getHistoryDetail: GET_HISTORYDETAIL,
      deleteItem: DELETE_DEXUATCTXTDT,
      toggleDetailPhuLucModal: TOGGLE_DETAIL_MODAL,
      getYKienPheDuyet: GET_YKIENPHEDUYET,
      getYKienPheDuyetGanNhat: GET_YKIENPHEDUYETGANNHAT
    }),
    back () {
      this.$router.push({ path: '/de-xuat-ct-xtdt' })
    },
    print () {
      localStorage.setItem('de_xuat_ct_xtdt', JSON.stringify(this.detailItem))
      const routeData = this.$router.resolve({ path: '/de-xuat-ct-xtdt/bieu-in' })
      window.open(routeData.href, '_blank')
    },
    async confirmDestroy () {
      await this.deleteItem(this.detailItem)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        this.isDeleteConfirmationModalOpen = false
        await this.$router.push({ path: '/de-xuat-ct-xtdt' })
      }
    },
    getDownloadURL (url) {
      return url && (url.indexOf('http://') > -1 || url.indexOf('https://') > -1) ? url : appConfig.BASE_HOST + url
    },
    getDay: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD') : '',
    getMonth: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('MM') : '',
    getYear: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('YYYY') : ''
  },
  async mounted () {
    if (this.$route.params.id) await this.getItem(this.$route.params.id)
    // if (this.itemid) await this.getHistoryDetail(this.itemid)
    if (this.detailItem.trangThai === 3) {
      await this.getYKienPheDuyetGanNhat(this.$route.params.id)
      this.yKienGanNhat = this.yKienPheDuyetGanNhat
    }
    if (this.detailItem.dinhKem) this.Attachments = JSON.parse(this.detailItem.dinhKem)
    this.optionsTinhThanh = await tinhThanhService.getAll()
    this.optionsNganhLinhVuc = await nganhLinhVucService.getAll()
    this.optionsQuocGia = await quocGiaService.getAll()
  },
  watch: {
    // itemid: function (val) {
    //   console.log('Detail có id: ', val)
    //   this.getHistoryDetail(this.itemid)
    // }
    // 'this.$route.params.id': async function (val) {
    //   await this.getItem(val)
    // }
  }
}
</script>

<style scoped>

</style>
